var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"floor-plan-details"},[_c('div',{staticClass:"container fluid"},[(!_vm.isEmpty(_vm.floorPlan))?_c('div',{staticClass:"content"},[_c('floor-plan-detail-header',{staticClass:"card mb-1",attrs:{"floorPlan":_vm.floorPlan,"projectRoute":{
          name: 'ManageProjectsDetails',
          params: {
            id: _vm.floorPlan.project.id
          }
        },"unitTypeRoute":{
          name: 'ManageProjectUnitTypesDetails',
          params: {
            projectId: _vm.floorPlan.project.id,
            id: _vm.floorPlan.projectUnitType.id
          }
        }}}),_c('floor-plan-detail-body',{staticClass:"card mb-1",attrs:{"floorPlan":_vm.floorPlan}}),_c('collapsible',{staticClass:"card p-1 mb-1",scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h4',[_vm._v("Units")])]},proxy:true}],null,false,1460309123),model:{value:(_vm.isUnitShown),callback:function ($$v) {_vm.isUnitShown=$$v},expression:"isUnitShown"}},[_c('div',{staticClass:"sm-p-2 col-12"},[_c('units',{attrs:{"type":"developer","canAdd":_vm.canAddUnit,"canEdit":_vm.canEditUnit,"canDelete":_vm.canDeleteUnit,"projectId":_vm.$route.params.projectId,"unitTypeId":_vm.$route.params.unitTypeId,"floorPlanId":_vm.$route.params.id}})],1)])],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }